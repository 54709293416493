import { ref } from "vue";

export function usePopConfirm() {

  let next : Function;
  const showPopConfirm = ref(false);

  /**
   * открытие модалки "у вас есть несохраненные изменения"
   */
  function openPopConfirm(value: boolean, _next: Function) {
    showPopConfirm.value = value;
    next = _next;
  }

  /**
   * actions у модалки "у вас есть несохраненные изменения"
   * */
  function popConfirmEvent(event: string, saveFunction?: Function) {
    switch (event) {
      case 'dontSaveAndExit': {
        if (next) next();
        break;
      }
      case 'saveAndExit': {
        if (saveFunction) saveFunction();
        if (next) next();
        break;
      }
    }
    showPopConfirm.value = false;
  }

  return {
    showPopConfirm,
    openPopConfirm,
    popConfirmEvent,
  };
}
